.center-block {
  display: grid;
  place-items: center;
}

.center-text {
  text-align: center;
}

.right-text {
  text-align: right;
}

.gray-text {
  color: gray;
}

.league-list-element {
  font-size: 18px;
  border-style: solid;
  border-radius: 20%;
  border-color: gray;
  color: black;
  padding: 5px;
  margin: 5px;
  text-decoration: none;
}

.league-list-element:hover {
  background-color: grey;
}

.game-entry {
  font-size: 18px;
  border-style: solid;
  border-radius: 4%;
  border-color: gray;
  color: black;
  padding: 5px;
  margin: 5px;
  text-decoration: none;
}

.delete-game-button {
  float: bottom;
  margin-left: 10px;
  font-size: 70%;
  /* background: rgb(202, 60, 60); */
}

.container {
  display: grid;
}

.left-half {
  grid-column: 1;
}

.right-half {
  grid-column: 2;
}

.game-winner {
  font-size: 16px;
  font-weight: bold;
}

.game-looser {
  font-size: 16px;
  color: gray;
}

.game-score {
  border-style: solid;
  border-width: 2px;
  border-radius: 10%;
  border-color: gray
}

.game-winner-rating-diff {
  color: green;
  font-size: 14px;
}

.game-looser-rating-diff {
  color: red;
  font-size: 14px;
}

.small-text {
  font-size: 12px;
}

.finger-pointer:hover {
  cursor: pointer;
}

.margin-10 {
  margin-top: 10px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-20 {
  margin-right: 20px;
}

.padding-10 {
  padding: 10px;
}

.margin-horizontal-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.margin-vertical-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.margin-vertical-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.with-spaces>* {
  margin: 30px 0;
}

.borderless-table {
  border-collapse: collapse;
}

.tie-break-input {
  float: right;
}

.rbc-calendar {
  min-height: 500px;
}

img {
  width: 100%;
  max-width: 600px;
}

.inline {
  display: inline-block;
  vertical-align: middle;
}